import { render, staticRenderFns } from "./MovimientoArticulo.vue?vue&type=template&id=c9826834&scoped=true"
import script from "./MovimientoArticulo.vue?vue&type=script&lang=js"
export * from "./MovimientoArticulo.vue?vue&type=script&lang=js"
import style0 from "./MovimientoArticulo.vue?vue&type=style&index=0&id=c9826834&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9826834",
  null
  
)

export default component.exports