import { render, staticRenderFns } from "./ConsultaInventario.vue?vue&type=template&id=784077f0"
import script from "./ConsultaInventario.vue?vue&type=script&lang=js"
export * from "./ConsultaInventario.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports